<template>
    <div>
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{(isEdit && !isLook)?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
            <a-spin :spinning="isLoading">
                <a-row v-if="!isLoading">
                    <a-col :span="24">
                        <a-col :span="24" >
                            <a-col :span="8">
                                <a-form-item required :label="l('issueprogress.Progress')">
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-input-number
                                        default-value="1"
                                        style="width: 100%;before:'%'"
                                        @change="issueChange"
                                        :min="0" :max="100"
                                        placeholder="0-100"
										v-model="entity.progress"
                                >
                                </a-input-number>
                                
                            </a-col>
                            <a-col :span="1" style="font-size: 16px;line-height: 30px;text-align: right;">%</a-col>
							<a-col :span="11">
								<span v-if="prompt" style="color: red;">{{l('this_progress_value_already')}}</span>
							</a-col>
							
                        </a-col>
						<a-col :span="24" style="margin-top: -10px;">
							<a-col :span="8">
							    <a-form-item required :label="l('issueprogress.name')">
							    </a-form-item>
							</a-col>
							<a-col :span="9">
							    <a-input v-model="entity.name" style="width: 200px;"></a-input>
							</a-col>
						</a-col>
                        <a-col :span="24" style="margin-top: -10px;">
                            <a-col :span="8">
                                <a-form-item :label="l('issueprogress.forecolor')">
                                </a-form-item>
                            </a-col>
                            <a-col :span="10">
                                <input type="color" style="width: 200px;height: 40px; border: transparent;"
                                       v-model="foreColor" @change="colorselect" title="点击拾色器外面关闭拾色器" id="myColor">
                            </a-col>
                        </a-col>


                        <a-col :span="24" style="margin-top: -10px;margin-bottom: -20px;">
                            <a-col :span="8">
                                <a-form-item :label="l('issueprogress.backcolor')">
                                </a-form-item>
                            </a-col>
                            <a-col :span="10">
                                <input type="color" style="width: 200px;height: 40px; border: transparent;"
                                       v-model="backColor" @change="colorselect" title="点击拾色器外面关闭拾色器" id="myColor2">
                            </a-col>
                        </a-col>

                    </a-col>
                    <a-col :span="12">

                    </a-col>
                </a-row>
                <br>
            </a-spin>
        </a-config-provider>
        <!-- 按钮 -->
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { IssueProgressServiceProxy } from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'the-progress',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        isLook: false,
		        id: undefined,
		        isLoading: false,//加载中
		        Eqm_am_IssueStatus: undefined,
				entity: [],
		        issueList: [],//问题类型
		        typeList: [],//点检类型
		        issueId: undefined,
		        prompt: undefined,
		        Threshold: undefined,
		        disabled: false,
                foreColor:"black",
                backColor:"#ffffff",
				isclick: true,
		    }
		},
		created() {
		    _this = this;
		    this.fullData(); // 模态框必须,填充数据到data字段
			this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		    if (this.isEdit) {
		        console.log(this.id)
		    }
		    if (this.id) {
		        this.disabled = !this.disabled;
		    }
		
		
		    this.getData()
		},
		methods: {
		    save() {
		        if (!this.entity.progress) {
		            if (this.entity.progress < 0) {
		                return abp.message.error("请输入进度值")
		            }
		        }
		        if (!this.entity.name) {
		            abp.message.info('名称不可为空');
		            return;
		        }
		
		        if (this.Threshold === this.entity.progress) {
		            return abp.message.error("已存在此进度值，保存失败")
		        }
				this.entity.foreColor=this.foreColor;
		        this.entity.backColor=this.backColor;
				var issueProgress = {issueProgress:this.entity};
				if(this.isclick) {
					this.isclick = false;
					this.IssueProgressServiceProxy.createOrUpdate(
					    issueProgress
					).finally(() => {
						this.close()
					}).then(res => {
						this.isclick = true;
					    this.success(true);
					})
				}
		    },
		    getData() {
		        this.isLoading = true;
		        this.IssueProgressServiceProxy.getForEdit(
		            this.id
		        ).finally(() => {
		            this.isLoading = false;
		        }).then(res => {
					console.log(res);
		            this.entity = res.issueProgress;
					if(res.issueProgress.backColor==null) {
					} else{
						this.backColor=res.issueProgress.backColor;
					}
					if(res.issueProgress.foreColor==null) {
					} else{
						this.foreColor=res.issueProgress.foreColor;
					}
		        })
		    },
		    colorselect() {
		        console.log(this.entity.color)
		    },
		    issueChange() {
		        if (isNaN(this.entity.progress)) {
		            return abp.message.warn(l('Theinputisnotanumber'))
		        } else {
		            this.IssueProgressServiceProxy.getByData(
		                this.entity.progress == null ? undefined : this.entity.progress
		            ).finally(() => {
		                this.isLoading = false;
		            }).then(res => {
		                console.log(res)
		
		                this.Threshold = res.progress;

		                if (res.progress === this.entity.progress) {
		                    this.prompt = true;
		                } else {
		                    this.prompt = false;
		                }
		            })
		            console.log(this.entity.progress)
		            if (this.entity.progress > 100) {
		                console.log("超出范围")
		            }
		        }
		
		    }
		}
	}
</script>

<style>
</style>
